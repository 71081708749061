import { NotificationStatus } from '~/types/components/Notification/NotificationStatus';

const successCssClasses = {
  border: 'border-EXPONDO-green',
  background: 'bg-EXPONDO-green-light',
  icon: 'icon-checkmark text-xs text-EXPONDO-white bg-EXPONDO-green p-0.5 rounded-full block',
};
export const notificationCssClasses = {
  [NotificationStatus.info]: {
    border: 'border-EXPONDO-primary-60',
    background: 'bg-EXPONDO-primary-5',
    icon: 'icon-info text-EXPONDO-primary-60',
  },
  [NotificationStatus.success]: successCssClasses,
  [NotificationStatus.secondarySuccess]: {
    ...successCssClasses,
    background: '',
  },
  [NotificationStatus.warning]: {
    border: 'border-EXPONDO-gold',
    background: 'bg-EXPONDO-yellow-light',
    icon: 'icon-warning text-xs text-EXPONDO-grey bg-EXPONDO-gold p-2 pl-1.5 rounded-full block w-4 h-4 text-center',
  },
  [NotificationStatus.error]: {
    border: 'border-EXPONDO-red',
    background: 'bg-EXPONDO-red-light',
    icon: `icon-zoom-out text-xs text-EXPONDO-white bg-EXPONDO-red 
    p-0.5 rounded-full block w-4 h-4 transform-gpu rotate-45`,
  },
};
